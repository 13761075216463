import React from "react"
import { PythonCard } from "../../components/blog/pythonCard"
import { Category } from "../../components/category/Category"

export const Python = () => {
  return (
    <>
      {/*  <Slider />*/}
      <Category />
      <PythonCard />
    </>
  )
}