import React from "react"
import { AngularCard } from "../../components/blog/angularCard"
import { Category } from "../../components/category/Category"

export const Angular = () => {
  return (
    <>
      {/*  <Slider />*/}
      <Category />
      <AngularCard />
    </>
  )
}