import React from "react";
import "./About.css";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { nav } from "../../assets/data/data";


export const About = () => {
  return (
    <div className='about_container'>
      <section className='newPost'>
        <div className='boxItems'>
          <h1 className="animatedHeader">Unlock Your Coding Potential And Secure Your Dream Job</h1>
          <p>
          Welcome to our educational coding hub, where preparation meets ambition! Unleash your full potential with our expertly crafted coding interview prep. Given the high demand for tech jobs, refining your skills in tackling coding interview questions can make you stand out. We provide a curated collection of resources to help you scale your coding interviews.
          </p>
          <p>
          Whether you are a beginner or a seasoned professional, our meticulously crafted interview questions and answers bridge the gap between your aspirations and coding interview mastery. Start now to unlock a realm of expertise and conquer your interviews effortlessly. Choose excellence. Choose us. 
          </p>
          <h1 className="animatedHeader">Explore Our Knowledge Base: Your Gateway to Expertise</h1>
          <p>
          Looking for proven means to practice for your upcoming coding interviews? We've got you covered. Our platform is an unparalleled treasure trove that will bring you one step closer to success in the coding world! We offer an extensive array of expertly crafted interview questions. Our collection includes React.js interview questions, Node.js interview questions, MongoDB interview questions, MERN (DSA-based) interview questions, and javascript coding interview questions.             
          <br /><br />
          <div className="links-row">
              {/* <Link to="/react-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> React.js Questions
              </Link>
              <Link to="/node-express-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> Node.js Questions
              </Link>
              <Link to="/mongodb-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> MongoDB Questions
              </Link> */}
            {nav.map((link) => (
              <Link  to={link.url} className="custom-link">
                    <span className="goto-icon">↗</span>   {link.page}
              </Link>
              ))}
            </div>
          </p>

          <p>
          Our platform is not just a learning space; it's a transformative journey tailored for beginners and experienced developers. Dive deep into intricacies, unravel complexities, and emerge with a profound comprehension of these cutting-edge technologies. 
          </p>
          <p>
          With a commitment to excellence, we go beyond the basics, ensuring you master the nuances of coding effortlessly. Ready to redefine your coding narrative? Browse through our curated resources to propel you toward coding interview mastery!
          </p>

          <h1 className="animatedHeader">Why Choose Us?</h1>
          <p>
          At InterviewCraze, we redefine success in the tech world through unparalleled coding interview preparation. Whether you're a coding novice or a professional developer, our comprehensive questions and answers cater to all skill levels. Our platform stands out with real-world scenario questions that can be posed by leading tech companies. Familiarize yourself with these interview questions that adhere to industry standards to bolster your confidence in your next interview.          </p>

          <h1 className="animatedHeader">Comprehensive Learning</h1>
          <p>
          Our collection spans diverse topics within React.js, Node.js, MongoDB, MERN (DSA-based), and Javascript. We provide practical React.js interview questions, Node.js interview questions, mongodb interview questions, MERN (DSA-based) interview questions,  and javascript programming questions for developers of all skill levels. 
          </p>
          <p>
          Our resource collection includes React.js interview Question, node interview  questions, mongodb interview questions, advanced mongodb interview questions, javascript coding questions, javascript basic interview questions, and javascript technical interview questions. These challenges are carefully curated by experts in these fields and mirror actual coding interview experiences. 
          </p>
          <p>
          Our mission is not just to teach you code but also to instill problem-solving prowess. This will help you uncover the secrets of algorithmic thinking and data structures, empowering you to ace coding interviews confidently. 
          </p>

          <h1 className="animatedHeader">Effortless Navigation</h1>
          <p>
          Navigating the intricate realm of coding interview preparation has never been more seamless than with our website. Enjoy a user-friendly interface tailored for both beginners and seasoned experts, exploring the nuances of React.js, Node.js, MongoDB, MERN (DSA-based), and Javascript interview questions. Our well-designed interface ensures an effortless navigation experience, enabling users to easily access specific questions and answers, optimizing their learning path. 
          </p>
          <p>
          Each section encapsulates a specific skill set, ensuring a focused learning experience. We use language that both novices and professionals can understand. Our website also adapts to diverse devices, offering consistent and smooth interaction. Thus, you can easily browse through our extensive database of resources and interview questions.
          </p>

          <h1 className="animatedHeader">Practical Coding Interview Questions that paint Real-World Scenarios</h1>
          <p>
          With real-world scenarios and in-depth explanations, our platform transcends the norm, ensuring you not only ace your interviews but thrive in dynamic coding environments. We don't just keep pace. We set the pace with our cutting-edge coding interview preparation services. 
           </p>
           <p>
           Our vast resources in mastering React.js interview questions, Node.js interview questions, MongoDB interview questions, MERN (DSA-based) interview questions, and Javascript interview questions will guide you to develop skills that will put you in demand everywhere. We've curated coding interview questions using the experiences of experts who have worked for top tech companies that will help with your professional development. 
           </p>
           <p>
           We know that programming interviews can be challenging and nerve-wracking. Sometimes, candidates are given take-home projects that require substantial effort, eg. coding interview questions javascript. However, interviewers use these types of projects to assess your on-the-job performance. Our tutorials are self-paced and cover all skill levels. After going through our knowledge base, you'll be sure to gain job-ready skills that will prepare you to work anywhere.
           </p>


          <h1 className="animatedHeader">A Guide To Build Your Portfolio</h1>
          <p>
          Our vast resource base contains multiple interview questions that can challenge you in different ways. They will help you to practice with coding projects in React.js, Node.js, MongoDB, MERN, and javascript programming questions. Take time, work on these challenges, and add them to your resume. They will be an excellent way to showcase what you can do to prospective employers. 
          </p>

        <h1 className="animatedHeader">Our Diverse Audience </h1>
          <p>
          We don't just teach; we empower. Our platform caters to individuals at all levels of expertise. Whether you're a recent graduate, a professional developer, or a coding enthusiast seeking to enhance your knowledge, our resources are tailored to suit your learning needs. That is why we boast a diverse, skilled audience who have found their way to various roles at leading tech companies. 
          </p>


          <h1 className="animatedHeader">Stay Updated</h1>
          <p>
          Stay ahead in the fast-evolving coding landscape with our comprehensive coding interview preparation platform. We cater to both beginners and experts, offering up-to-date interview questions for react js, Node.js, MongoDB, MERN (DSA-based), and multiple Javascript interview questions. Our meticulously curated content not only prepares you for interviews but also keeps you abreast of the latest coding trends and terminologies.
          </p>

          <p>
          Our well-researched answers will ensure you're well-versed in the technologies shaping modern web development. Our platform will serve as your coding compass, guiding you through the intricacies of today's tech landscape.
          </p>


          {/* <h1 className="animatedHeader">Get Started Now</h1>
          <p>
            <div className="links-row">
              <Link to="/react-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> React.js Questions
              </Link>
              <Link to="/node-express-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> Node.js Questions
              </Link>
              <Link to="/mongodb-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> MongoDB Questions
              </Link>
            </div>
          </p> */}
          
          
        </div>
      </section>
      <Helmet>
      <title>Interview Questions for Node.js, React.js and MongoDB | InterviewCraze</title>
      <meta
            name='keywords'
            content="interview questions on react js | common react js interview questions | node js developer interview questions | node interview questions | node js interview questions | react js interview questions | node js coding question | basic node js questions | mongo db interview | mongodb questions | interview questions mongodb | javascript interview questions | coding interview questions javascript"
    /> 
      <meta name="description" content="Land your dream job with InterviewCraze. Dive into a curated collection of React.js, Node.js, & MongoDB resources. Get expert tips, articles, & interview questions." />
      </Helmet>
    </div>
  );
};

export default About;
