import React from "react"
import { HtmlCard } from "../../components/blog/htmlCard"
import { Category } from "../../components/category/Category"

export const Html = () => {
  return (
    <>
      {/*  <Slider />*/}
      <Category />
      <HtmlCard />
    </>
  )
}