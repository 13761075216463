import React from "react"
import { JavascriptCard } from "../../components/blog/javascriptCard"
import { Category } from "../../components/category/Category"

export const Javascript = () => {
  return (
    <>
      {/*  <Slider />*/}
      {/* <Category /> */}
      <JavascriptCard />
    </>
  )
}