import React from "react"
import { NodeCard } from "../../components/blog/nodeCard"
import { Category } from "../../components/category/Category"

export const Node = () => {
  return (
    <>
      {/*  <Slider />*/}
      {/* <Category /> */}
      <NodeCard />
    </>
  )
}