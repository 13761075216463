import React from "react"
import { CssCard } from "../../components/blog/cssCard"
import { Category } from "../../components/category/Category"

export const Css = () => {
  return (
    <>
      {/*  <Slider />*/}
      <Category />
      <CssCard />
    </>
  )
}