import React, { useState } from "react";
import "./blog.css";
import { mongodb } from "../../assets/data/mongodb";
import { AiOutlineTags, AiOutlineClockCircle, AiOutlineComment, AiOutlineShareAlt } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';


export const MongodbCard = () => {
  // Define the number of cards per page
  const cardsPerPage = 27;

  // Initialize the current page state
  const [currentPage, setCurrentPage] = useState(1);

  // Sort the blog data by date in descending order (latest date first)
  const sortedBlog = [...mongodb].sort((a, b) => {
    const idA = a.id;
    const idB = b.id;
    return idA - idB;
  });

  // Calculate the index range for the cards to display on the current page
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;

  // Filter the sorted blog data based on the current page
  const currentBlogData = sortedBlog.slice(startIndex, endIndex);

  // Function to handle pagination
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <section className="blog">
      <h1>MongoDB Interview Questions</h1>
      <p>Get one step closer to your dream job. Navigate through our collection of standard and unique mongobd interview questions and delve into the intricacies of backend development to prepare for your mongodb interview test. Our mongodb questions and answers cover the spectrum – from fundamental concepts for beginners to advanced mongodb interview questions for experienced developers. This collection of mongodb quiz tests will properly test your knowledge and help you master interview questions mongodb.</p>
      <p>We provide answers and concept explanations that meet industry standards, ensuring you're well-versed in modern tech nuances for your interview questions on mongodb. By mastering these concepts, you'll be sure to navigate your mongodb interview process confidently, armed with practical knowledge that sets you apart. Join the ranks of our success stories in mongodb interview experience; choose coding prowess, choose a future of limitless possibilities today.</p>
      <h1>Here We Go !!</h1>
 
        <div className="container grid3"> 
          {currentBlogData.map((item) => (
            <div className="box boxItems" key={item.id}>
              <div className="img">
                {/* <img src={item.cover} alt="" /> */}
              </div>
              <div className="details">
                <div className="tag">
                  <AiOutlineTags className="icon" />
                  <p style={{ color: 'blue' }}>{item.category}</p>
                </div>
                <Link to={`/mongodb-interview-questions/${item.title.substring(3).replace(/ /g, '-')}-${item.id}`} className="link">
                  <h3>{item.title}</h3>
                </Link>
                {/* <div className="date">
                  <AiOutlineClockCircle className="icon" /> <label htmlFor="">{item.date}</label>
                  <AiOutlineComment className="icon" /> <label htmlFor="">27</label>
                  <AiOutlineShareAlt className="icon" /> <label htmlFor="">SHARE</label>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* Pagination */}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={endIndex >= sortedBlog.length}
        >
          Next
        </button>
      </div>

      <Helmet>
          <meta
            name='keywords'
            content="mongodb interview questions | mongo db quiz | interview questions mongodb | mongodb questions| advanced mongodb interview questions| interview questions on mongodb| mongodb dba interview questions| mongodb interview experience| mongodb interview process| mongodb interview test"
          />
          <title>MongoDB Interview Questions | Mongo Db Quizzes | Interviewcraze</title>
          <meta name="description" content="Here's a list of frequently asked MongoDB interview questions and Mongo DB quiz at InterviewCraze. We provide for beginners as well as professionals." />

        </Helmet>
    </>
  );
};
