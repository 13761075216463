import React from "react"
import { MongodbCard } from "../../components/blog/mongodbCard"
import { Category } from "../../components/category/Category"

export const Mongodb = () => {
  return (
    <>
      {/*  <Slider />*/}
      {/* <Category /> */}
      <MongodbCard />
    </>
  )
}