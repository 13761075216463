import React from "react"
import { JavaCard } from "../../components/blog/javaCard"
import { Category } from "../../components/category/Category"

export const Java = () => {
  return (
    <>
      {/*  <Slider />*/}
      <Category />
      <JavaCard />
    </>
  )
}