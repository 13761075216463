import React from "react"
import { DataScienceCard } from "../../components/blog/dataScienceCard"
import { Category } from "../../components/category/Category"

export const DataScience = () => {
  return (
    <>
      {/*  <Slider />*/}
      <Category />
      <DataScienceCard />
    </>
  )
}