import React, { useState } from "react";
import "./blog.css";
import { mern } from "../../assets/data/mern";
import { AiOutlineTags, AiOutlineClockCircle, AiOutlineComment, AiOutlineShareAlt } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';


export const MernCard = () => {
  // Define the number of cards per page
  const cardsPerPage = 27;

  // Initialize the current page state
  const [currentPage, setCurrentPage] = useState(1);

  // Sort the blog data by date in descending order (latest date first)
  const sortedBlog = [...mern].sort((a, b) => {
    const idA = a.id;
    const idB = b.id;
    return idA - idB;
  });

  // Calculate the index range for the cards to display on the current page
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;

  // Filter the sorted blog data based on the current page
  const currentBlogData = sortedBlog.slice(startIndex, endIndex);

  // Function to handle pagination
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <section className="blog">
      <h1>Data Structures and Algorithms Based react, node, express, mongodb interview questions</h1>
      <p>
      Prepare to excel in your MERN interviews with our comprehensive MERN (DSA-based) interview questions and answers. Tailored for both beginners and expert developers, our carefully crafted content ensures you're ready for any challenge during your MERN interview. Our MERN (DSA-based) interview test questions and answers can be used for real-world applications, preparing you for success. 
      </p>
      <p>
      Whether you're a seasoned developer or just starting, our content empowers you to showcase your skills effectively. Our approach ensures you master data structures and algorithms vital for excelling in coding interviews. We'll guide you through writing various programs that can perform different tasks. We also encourage gaining practical knowledge in the various aspects of MERN coding. This makes us unrivaled as we are focused on propelling you to a successful job interview. 
      </p>
      <p>
      Stay ahead in the competitive tech landscape by practicing with our MERN interview questions today.
      </p>
      <h1>Here We Go !!</h1>

        <div className="container grid3"> 
          {currentBlogData.map((item) => (
            <div className="box boxItems" key={item.id}>
              <div className="img">
                {/* <img src={item.cover} alt="" /> */}
              </div>
              <div className="details">
                <div className="tag">
                  <AiOutlineTags className="icon" />
                  <p style={{ color: 'blue' }}>{item.category}</p>
                </div>
                <Link to={`/Data-Structures-and-Algorithms-Based-react-node-express-mongodb-interview-questions/${item.title.substring(3).replace(/ /g, '-')}-${item.id}`} className="link">
                  <h3>{item.title}</h3>
                </Link>
                {/* <p>{item.desc.slice(0, 180)}...</p>
                <div className="date">
                  <AiOutlineClockCircle className="icon" /> <label htmlFor="">{item.date}</label>
                  <AiOutlineComment className="icon" /> <label htmlFor="">27</label>
                  <AiOutlineShareAlt className="icon" /> <label htmlFor="">SHARE</label>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* Pagination */}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={endIndex >= sortedBlog.length}
        >
          Next
        </button>
      </div>
      <Helmet>
          <title>Data Structures and Algorithms Based react node express mongodb interview questions | InterviewCraze</title>
          <meta
            name='keywords'
            content="Data Structures and Algorithms Based react js node js express js mongodb interview questions | interview questions on react js | common react js interview questions | node js developer interview questions | node interview questions | node js interview questions | react js interview questions | node js coding question | basic node js questions | mongo db interview | mongodb questions | interview questions mongodb | javascript interview questions | coding interview questions javascript"
    />     
        <meta name="description" content="Land your dream job with InterviewCraze. Dive into a curated collection of React.js, Node.js, & MongoDB resources. Get expert tips, articles, & interview questions." />
 
        </Helmet>
    </>
  );
};
