export const nav = [
  {
    id: 1,
    text: "React.Js",
    page: "React.Js Interview Questions",
    url: "/react-interview-questions",
  },
  {
    id: 2,
    text: "Node.Js",
    page: "Node.Js Interview Questions",
    url: "/node-express-interview-questions",
  },
  {
    id: 3,
    text: "MongoDB",
    page: "MongoDB Interview Questions",
    url: "/mongodb-interview-questions",
  },
  // {
  //   id: 4,
  //   text: "Snowflake",
  //   page: "Javascript Interview Questions",
  //   url: "/snowflake",
  // },
  // {
  //   id: 5,
  //   text: "Java",
  //   url: "/java",
  // },
  // {
  //   id: 6,
  //   text: "Python",
  //   url: "/python",
  // },
  // {
  //   id: 7,
  //   text: "SQL",
  //   url: "/sql",
  // },
  {
    id: 8,
    text: "MERN (DSA based)",
    page: "MERN (DSA based)  Interview Questions",
    url: "/Data-Structures-and-Algorithms-Based-react-node-express-mongodb-interview-questions",
  },
  // {
  //   id: 9,
  //   text: "DSA",
  //   url: "/dsa",
  // },
  {
    id: 10,
    text: "Javascript",
    page: "Javascript Interview Questions",
    url: "/javascript-interview-questions",
  },
  // {
  //   id: 11,
  //   text: "Angular",
  //   url: "/angular",
  // },
  // {
  //   id: 12,
  //   text: "Data Science",
  //   url: "/dataScience",
  // },
  // {
  //   id: 13,
  //   text: "HTML",
  //   url: "/html",
  // },
  // {
  //   id: 14,
  //   text: "CSS",
  //   url: "/css",
  // },
  // {
  //   id: 15,
  //   text: "About",
  //   url: "/about",
  // },
  // {
  //   id: 16,
  //   text: "Contact",
  //   url: "/contact",
  // },
  
]
export const category = [
  
]
export const blog = [
  
]


export const node = [
  
]
