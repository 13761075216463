import React from "react"
import { AiFillTwitterCircle, AiFillLinkedin } from "react-icons/ai"
import { BsFacebook } from "react-icons/bs"
import { RiInstagramFill } from "react-icons/ri"
import "./footer.css";
import icon from "../../assets/images/interviewCraze_icon.png"


export const Footer = () => {
  return (
    <>
      <footer className='boxItems'>
        <div className='container flex'>
          <p><img src={icon} alt='logo' width='20px' height='15px' /> InterviewCraze - All rights reserved - 2024</p>
          <p>Reach out to us at :  <a href="mailto:support@interviewcraze.com">support@interviewcraze.com</a></p>

          <div className='social'>
            {/* <BsFacebook className='icon' />
            <RiInstagramFill className='icon' />
            <AiFillTwitterCircle className='icon' />
            <AiFillLinkedin className='icon' /> */}
          </div>
        </div>
      </footer>
    </>
  )
}
