import React from "react"
import { SqlCard } from "../../components/blog/sqlCard"
import { Category } from "../../components/category/Category"

export const Sql = () => {
  return (
    <>
      {/*  <Slider />*/}
      <Category />
      <SqlCard />
    </>
  )
}