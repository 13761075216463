import React from "react"
import { ReactCard } from "../../components/blog/reactCard"
import { Category } from "../../components/category/Category"

export const Reactjs = () => {
  return (
    <>
      {/*  <Slider />*/}
      {/* <Category /> */}
      <ReactCard />
    </>
  )
}