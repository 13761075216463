import React from "react"
import { SnowflakeCard } from "../../components/blog/snowflakeCard"
import { Category } from "../../components/category/Category"

export const Snowflake = () => {
  return (
    <>
      {/*  <Slider />*/}
      <Category />
      <SnowflakeCard />
    </>
  )
}

