import React from "react"
import { DsaCard } from "../../components/blog/dsaCard"
import { Category } from "../../components/category/Category"

export const Dsa = () => {
  return (
    <>
      {/*  <Slider />*/}
      <Category />
      <DsaCard />
    </>
  )
}