import React, { useState } from "react";
import logo from "../../assets/images/interviewCraze_logo.png";
import "./header.css";
import { nav } from "../../assets/data/data";
import { HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";

import { Link } from "react-router-dom";

export const Header = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <Link exact to="/" className="nav-logo">
          <img src={logo} alt='logo' width='290px' height='28px' />
          </Link>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
          {nav.map((link) => (
            <li className="nav-item" key={link.id}>
            <Link 
            to={link.url}
            activeClassName="active"
            className="nav-links"
            onClick={handleClick}>
                  {link.text}
            </Link>
          </li>
          ))}
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

            {click ? (
              <span className="icon">
                <HamburgetMenuClose />{" "}
              </span>
            ) : (
              <span className="icon">
                <HamburgetMenuOpen />
              </span>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
