import React, { useState } from "react";
import "./blog.css";
import { javascript } from "../../assets/data/javascript";
import { AiOutlineTags, AiOutlineClockCircle, AiOutlineComment, AiOutlineShareAlt } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';


export const JavascriptCard = () => {
  // Define the number of cards per page
  const cardsPerPage = 27;

  // Initialize the current page state
  const [currentPage, setCurrentPage] = useState(1);

  // Sort the blog data by date in descending order (latest date first)
  const sortedBlog = [...javascript].sort((a, b) => {
    const idA = a.id;
    const idB = b.id;
    return idA - idB;
  });

  // Calculate the index range for the cards to display on the current page
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;

  // Filter the sorted blog data based on the current page
  const currentBlogData = sortedBlog.slice(startIndex, endIndex);

  // Function to handle pagination
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <section className="blog">
      <h1>Javascript Interview Questions</h1>
      <p>
      Your success in acing coding interview questions javascript begins with our comprehensive javascript coding interview questions prep. We offer javascript basic interview questions and javascript technical interview questions for beginners and experienced javascript developers. As Javascript is the most popular programming language, becoming a master is key to unlocking new opportunities as a developer
      </p>
      <p>
      Our js coding interview questions and answers are prepared by experts and broken down to make it easy to understand. From fundamental concepts to advanced ones, we've got you covered throughout your journey toward mastery of javascript  programming questions. The questions simulate real-world scenarios and will help familiarize you with basic and advanced concepts. 
      </p>
      <p>
      Uncover the secrets of javascript, the language that powers the web, and become a javascript juggernaut today by practicing with our javascript interview questions and answers today.
      </p>
      <h1>Here We Go !!</h1>

        <div className="container grid3"> 
          {currentBlogData.map((item) => (
            <div className="box boxItems" key={item.id}>
              <div className="img">
                {/* <img src={item.cover} alt="" /> */}
              </div>
              <div className="details">
                <div className="tag">
                  <AiOutlineTags className="icon" />
                  <p style={{ color: 'blue' }}>{item.category}</p>
                </div>
                <Link to={`/javascript-interview-questions/${item.title.substring(3).replace(/ /g, '-')}-${item.id}`} className="link">
                  <h3>{item.title}</h3>
                </Link>
                {/* <p>{item.desc.slice(0, 180)}...</p> */}
                {/* <div className="date">
                  <AiOutlineClockCircle className="icon" /> <label htmlFor="">{item.date}</label>
                  <AiOutlineComment className="icon" /> <label htmlFor="">27</label>
                  <AiOutlineShareAlt className="icon" /> <label htmlFor="">SHARE</label>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* Pagination */}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={endIndex >= sortedBlog.length}
        >
          Next
        </button>
      </div>

      <Helmet>
          <meta
            name='keywords'
            content="javascript interview questions | javascript interview questions and answers | javascript coding interview questions | javascript coding questions | javascript technical interview questions | javascript basic interview questions | js coding interview questions"
          />
          <title>Javascript Interview Questions | InterviewCraze</title>
          <meta name="description" content="Here's a list of frequently asked MongoDB interview questions and Mongo DB quiz at InterviewCraze. We provide for beginners as well as professionals." />

        </Helmet>
    </>
  );
};
