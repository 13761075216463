import React, { useState } from "react"
import "./details.css"
import "../../components/header/header.css"
// import img from "../../assets/images/b5.jpg"
// import { BsPencilSquare } from "react-icons/bs"
// import { AiOutlineDelete } from "react-icons/ai"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
// import { blog } from "../../assets/data/data"
import { css } from "../../assets/data/css"

import { Helmet } from 'react-helmet-async';


export const CssDetailPages = () => {
  const { id } = useParams()
  const lastHyphenIndex = id.lastIndexOf('-');

  const result = id.substring(lastHyphenIndex + 1);

  const [blogs, setBlogs] = useState(null)

  useEffect(() => {
    let blogs = css.find((blogs) => blogs.id === parseInt(result))
    if (blogs) {
      setBlogs(blogs)
    }
  }, [result])

  return (
    <>
      {blogs ? (
        <section className='singlePage'>
          <div className='container'>
            
            <div className='right'>
            <p><b>Author: Divyansh Bhatnagar</b> </p>
            <p>Updated: {blogs.date}</p>
              {/* <div className='buttons'>
                <button className='button'>
                  <BsPencilSquare />
                </button>
                <button className='button'>
                  <AiOutlineDelete />
                </button>
              </div> */}
              <h1>{blogs.title}</h1>
              <div className='left'>
                <div>
                <img src={blogs.cover} alt='' />
                </div>
              
            </div>
              {/* <p>{blogs.desc}</p>
              <div>
              {blogs.desc.split('\n').map((line, index) => (
                  <p key={index}>{line}</p>
              ))}
              </div> */}
              <p dangerouslySetInnerHTML={{ __html: blogs.desc.replace(/\n/g, '<br>') }} />
              
            </div>
          </div>

          <Helmet>
          <meta
            name='keywords'
            content={blogs.title}
          />
        </Helmet>

        </section>
      ) : null}
    </>
  )
}
