import React, { useState } from "react"
import "./details.css"
import "../../components/header/header.css"
// import img from "../../assets/images/b5.jpg"
// import { BsPencilSquare } from "react-icons/bs"
// import { AiOutlineDelete } from "react-icons/ai"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
// import { blog } from "../../assets/data/data"
import { javascript } from "../../assets/data/javascript"

import { Helmet } from 'react-helmet-async';


export const JavascriptDetailPages = () => {
  const { id } = useParams()
  const lastHyphenIndex = id.lastIndexOf('-');

  const result = id.substring(lastHyphenIndex + 1);

  const [blogs, setBlogs] = useState(null)

  useEffect(() => {
    let blogs = javascript.find((blogs) => blogs.id === parseInt(result))
    if (blogs) {
      setBlogs(blogs)
    }
  }, [result])

  return (
    <>
      {blogs ? (
        <section className='singlePage'>
          <div className='container'>
            
            <div className='right'>
            {/* <p><b>Author: Divyansh Bhatnagar</b> </p> */}
              {/* <div className='buttons'>
                <button className='button'>
                  <BsPencilSquare />
                </button>
                <button className='button'>
                  <AiOutlineDelete />
                </button>
              </div> */}
              <h1>{blogs.title}</h1>
              <p style={{ color: '#365486', fontWeight: 'bold', fontStyle: 'italic' }}>Updated: {blogs.date}</p>

              <div className='left'>
                {/* <div>
                <img src={blogs.cover} alt='' />
                </div> */}
              
            </div>
              {/* <p>{blogs.desc}</p>
              <div>
              {blogs.desc.split('\n').map((line, index) => (
                  <p key={index}>{line}</p>
              ))}
              </div> */}
              <p dangerouslySetInnerHTML={{ __html: blogs.desc.replace(/\n/g, '<br>') }} />
              
            </div>
          </div>

          <Helmet>
          <meta
            name='keywords'
            content={blogs.title.concat(" | 50 MongoDB interview questions | MongoDB interview questions | MongoDB interview preparation | MongoDB interview guide | MongoDB interview tips | MongoDB queries interview questions | MongoDB aggregation interview questions | MongoDB schema design interview questions | MongoDB indexes interview questions | MongoDB CRUD operations interview questions | MongoDB performance tuning interview questions | MongoDB data modeling interview questions | MongoDB database interview questions | MongoDB NoSQL interview questions | MongoDB Atlas interview questions | MongoDB Shell interview questions | MongoDB transactions interview questions | MongoDB security interview questions | MongoDB backup and restore interview questions | MongoDB administration interview questions | MongoDB technical interview questions | MongoDB frequently asked questions | Best practices for MongoDB interviews | Advanced MongoDB interview questions | Common MongoDB interview questions | Practical MongoDB interview questions | MongoDB interview challenges | MongoDB certification interview questions | Mock MongoDB interview questions | Real-world MongoDB interview questions | Interview questions on MongoDB concepts | MongoDB job interview questions | MongoDB interview question bank | MongoDB interview Q&A | MongoDB interview techniques | MongoDB interview skills | MongoDB interview competency | MongoDB interview strategies | MongoDB interview assessment | Tips for cracking MongoDB interviews | MongoDB interview study material | MongoDB interview resources | MongoDB interview help | MongoDB interview secrets | MongoDB interview success techniques | MongoDB interview tricks | MongoDB interview hacks | Mastering MongoDB interviews | Acing MongoDB interviews | MongoDB interview insights")}
          />
          <meta
            name='title'
            content={blogs.title}
          />
          <title>InterviewCraze | {blogs.title}</title>
        </Helmet>

        </section>
      ) : null}
    </>
  )
}
