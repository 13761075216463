import React, { useState } from "react";
import "./blog.css";
import { react } from "../../assets/data/react";
import { AiOutlineTags, AiOutlineClockCircle, AiOutlineComment, AiOutlineShareAlt } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';


export const ReactCard = () => {
  // Define the number of cards per page
  const cardsPerPage = 27;

  // Initialize the current page state
  const [currentPage, setCurrentPage] = useState(1);

  // Sort the blog data by date in descending order (latest date first)
  const sortedBlog = [...react].sort((a, b) => {
    const idA = a.id;
    const idB = b.id;
    return idA - idB;
  });

  // Calculate the index range for the cards to display on the current page
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;

  // Filter the sorted blog data based on the current page
  const currentBlogData = sortedBlog.slice(startIndex, endIndex);

  // Function to handle pagination
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <section className="blog">
      <h1>React.js Interview Questions</h1>
      <p>
      Unlock your coding potential with our comprehensive React.js interview prep! Whether you're a beginner or a seasoned developer, our tailored content will ensure your success in coding questions for react interview. Dive into basic react interview questions to solidify your foundation or challenge yourself with advanced react js interview questions for experienced developers. 
      </p>
      <p>
      Our curated list of react js coding test questions and answers covers common react interview questions and key concepts, while the answers comprehensively explain these concepts. We understand the importance of mastering React.js, and our content is designed to empower you at every level. These questions for React.js interviews are curated from the experiences of professionals to help sharpen your expertise and navigate you through the intricacies of React.js. Thus, you will excel in your next coding interview when you test yourself with our js and react interview questions and answers. Your coding journey begins now
      </p>
      <h1>Here We Go !!</h1>
        <div className="container grid3"> 
          {currentBlogData.map((item) => (
            <div className="box boxItems" key={item.id}>
              <div className="img">
                {/* <img src={item.cover} alt="" /> */}
              </div>
              <div className="details">
                <div className="tag">
                  <AiOutlineTags className="icon" />
                  <p style={{ color: 'blue' }}>{item.category}</p>
                </div>
                <Link to={`/react-interview-questions/${item.title.substring(3).replace(/ /g, '-')}-${item.id}`} className="link">
                  <h3>{item.title}</h3>
                </Link>
                {/* <p>{item.desc.slice(0, 180)}...</p> */}
                {/* <div className="date">
                  <AiOutlineClockCircle className="icon" /> <label htmlFor="">{item.date}</label>
                  <AiOutlineComment className="icon" /> <label htmlFor="">27</label>
                  <AiOutlineShareAlt className="icon" /> <label htmlFor="">SHARE</label>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* Pagination */}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={endIndex >= sortedBlog.length}
        >
          Next
        </button>
      </div>
      <Helmet>
          <meta
            name='keywords'
            content="react js interview questions | react coding interview questions | reactjs interview questions and answers | interview questions on react js | react js interview questions | react js coding test questions and answers | react js interview questions for experienced | basic react interview questions | js and react interview questions | questions for react interview"
          />
        <title>React JS interview questions and answers | InterviewCraze</title>
        <meta name="description" content="Find React JS interview questions and answers at InterviewCraze and Conquer your interviews. Boost your confidence and ace the interview - Visit now for success!" />


        </Helmet>
    </>
  );
};
