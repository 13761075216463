import React, { useState } from "react"
import "./details.css"
import "../../components/header/header.css"
// import img from "../../assets/images/b5.jpg"
// import { BsPencilSquare } from "react-icons/bs"
// import { AiOutlineDelete } from "react-icons/ai"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
// import { blog } from "../../assets/data/data"
import { node } from "../../assets/data/node"

import { Helmet } from 'react-helmet-async';


export const NodeDetailPages = () => {
  const { id } = useParams()
  const lastHyphenIndex = id.lastIndexOf('-');

  const result = id.substring(lastHyphenIndex + 1);

  const [blogs, setBlogs] = useState(null)

  useEffect(() => {
    let blogs = node.find((blogs) => blogs.id === parseInt(result))
    if (blogs) {
      setBlogs(blogs)
    }
  }, [result])

  return (
    <>
      {blogs ? (
        <section className='singlePage'>
          <div className='container'>
            
            <div className='right'>
            {/* <p><b>Author: Divyansh Bhatnagar</b> </p> */}
              {/* <div className='buttons'>
                <button className='button'>
                  <BsPencilSquare />
                </button>
                <button className='button'>
                  <AiOutlineDelete />
                </button>
              </div> */}
              <h1>{blogs.title}</h1>
              <p style={{ color: '#365486', fontWeight: 'bold', fontStyle: 'italic' }}>Updated: {blogs.date}</p>
              

              <div className='left'>
                {/* <div>
                <img src={blogs.cover} alt='' />
                </div> */}
              
            </div>
              {/* <p>{blogs.desc}</p>
              <div>
              {blogs.desc.split('\n').map((line, index) => (
                  <p key={index}>{line}</p>
              ))}
              </div> */}
              <p dangerouslySetInnerHTML={{ __html: blogs.desc.replace(/\n/g, '<br>') }} />
              
            </div>
          </div>

          <Helmet>
          <meta
            name='keywords'
            content={blogs.title.concat(" | 50 node express interview questions | Node.js interview questions | Node.js questions | Node.js technical interview | Node.js developer interview | Node.js coding interview | Node.js programming questions | Node.js job interview | Node.js skills interview | Node.js experienced interview | Node.js beginner interview | Node.js interview preparation | Node.js interview tips | Node.js interview techniques | Node.js interview guide | Node.js interview resources | Node.js interview answers | Node.js interview examples | Node.js interview quiz | Node.js interview challenges | Node.js interview practice | Node.js interview assessments | Node.js interview test | Node.js interview pattern | Node.js interview FAQs | Node.js frequently asked questions | Node.js interview process | Node.js interview assessment | Node.js interview assessments | Node.js interview patterns | Node.js interview techniques | Node.js interview tactics | Node.js interview strategies | Node.js interview tricks | Node.js interview secrets | Node.js interview hacks | Node.js interview tips and tricks | Node.js interview guide | Node.js interview handbook | Node.js interview ebook | Node.js interview essentials | Node.js interview skills | Node.js interview techniques | Node.js interview knowledge | Node.js interview mastery | Node.js interview expertise | Node.js interview proficiency | Node.js interview competencies | Node.js interview insights | Node.js interview mastery | Node.js interview mastery | Node.js interview expertise | Node.js interview proficiency | Node.js interview competencies | Node.js interview insights | Node.js interview mastery | Node.js interview mastery | Node.js interview expertise | Node.js interview proficiency | Node.js interview competencies | Node.js interview insights")}
          />
          <meta
            name='title'
            content={blogs.title}
          />
          <title>InterviewCraze | {blogs.title}</title>
        </Helmet>

        </section>
      ) : null}
    </>
  )
}
